import * as React from 'react'
import PropTypes from 'prop-types'
import resolveAssetURL from '@/utils/resolveAssetURL'

import BlockText from '@/components/Block/Text'

import {
  Container,
  LeftWrapper,
  CenterWrapper,
  RightWrapper,
  Image
} from './style'

const TextImages = ({
  title = '',
  subtitle = '',
  actions = [],
  text = '',
  images = [],
  alt = [],
  display = 'regular',
  leftAlt = '',
  rightAlt = ''
}) => {
  return (
    <Container className={ display }>
      <LeftWrapper>
        { images[0] && <Image loading='lazy' src={ resolveAssetURL(images[0]) } alt={ leftAlt } /> }
      </LeftWrapper>
      <CenterWrapper>
        <BlockText
          title={ title }
          titleTag='h2'
          subtitle={ subtitle }
          actions={ actions }
          text={ text }
        />
      </CenterWrapper>
      <RightWrapper>
        { images[1] && <Image loading='lazy' src={ resolveAssetURL(images[1]) } alt={ rightAlt } /> }
      </RightWrapper>
    </Container>
  )
}

TextImages.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.array,
  text: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  alt: PropTypes.arrayOf(PropTypes.string),
  display: PropTypes.oneOf(['regular', 'inverted', 'big', 'small'])
}

export default TextImages
