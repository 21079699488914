import styled from '@emotion/styled'
import { mediaMax } from '@/styles/mixins'

import {
  Subtitle as TextSubtitle,
  Title as TextTitle,
  Wysiwyg as TextWysiwyg,
  Action as TextAction,
  Actions as TextActions
} from '@/components/Block/Text/style'

import {
  ButtonWrapper as Btn
} from '@/components/Button/style'

export const Wrapper = styled.div`
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
`

export const Image = styled.img`
  width: 100%;
  display: flex;
  align-items: center;
  object-fit: cover;
`

export const CenterWrapper = styled(Wrapper)`
  position: relative;
  padding-left: 5%;
  margin-top: 6rem;
  margin-bottom: 2rem;
  ${mediaMax.sm} {
    width: 100%;
  }
`

export const LeftWrapper = styled(Wrapper)`
  position: relative;
  ${mediaMax.xs} {
    padding: 0;
    width: 70%;
    left: ${p => p.theme.paddingX.m};
  }
`

export const RightWrapper = styled(Wrapper)`
  position: relative;
  height: 100%;
  ${mediaMax.xs} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  ${Image} {
    position: absolute;
    top: 22px;
    right: 0;
    width: 55%;
    ${mediaMax.xs} {
      right: ${p => p.theme.paddingX.m};
      position: static;
      width: 70%;
    }
  }
`

export const Container = styled.div`
  margin: 12rem 0;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 ${p => p.theme.paddingX.d};

  ${mediaMax.md} {
    padding: 0 ${p => p.theme.paddingX.t};
    overflow: hidden;
  }

  ${mediaMax.xs} {
    flex-direction: column;
    margin: 3rem 0;
    padding: 0;
    gap: 0rem;

    ${CenterWrapper} {
      width: 100%;

      ${TextSubtitle} {
          font-size: 1.1rem;
      }

      ${TextTitle} {
          margin-bottom: 1.5rem;
      }

      ${TextWysiwyg} {
          width: 90%;
      }

      ${TextAction} {
          margin-top: 4rem;
      }

      ${Btn}{
        padding: 1.7rem 3.5rem;
      }
    }
  }


  &.fullwidth{
    ${CenterWrapper}{
      width: 60%;
    }

    ${RightWrapper}{
      display: none;
    }
  }
  &.regular {
    ${RightWrapper} {
      width: calc(60% / 3);
      ${mediaMax.xs} {
        left: -${p => p.theme.paddingX.m};
      }

      ${Image} {
        width: 100%;
        ${mediaMax.xs}{
          width: 70%;
        }
      }
      ${mediaMax.xs}{
        width: 100%;
      }
    }
  }

  &.big {
    justify-content: flex-start;
    align-items: center;
    padding: 0 ${p => p.theme.paddingX.d} 0 0;

    ${mediaMax.xs} {
      padding: 0;
    }

    ${CenterWrapper} {
      width: 33%;
      padding-left: 8%;
      z-index: 99;

      ${mediaMax.xs} {
        width: 100%;
        padding-right: 8%;
      }

      ${TextSubtitle} {
        margin-bottom: 2rem;
        font-weight: initial;
      }

      ${TextTitle} {
        margin-bottom: 2rem;

        ${mediaMax.xs} {
          margin-bottom: 1.5rem;
        }
      }

      ${TextWysiwyg} {
        ${mediaMax.xs} {
          width: 90%;
        }
      }

      ${TextAction} {
        margin-top: 2rem;

        ${mediaMax.xs} {
          margin-top: 4rem;
        }
      }
      ${TextActions} {
        &.many {
          width: 42vw;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          flex-wrap: nowrap;
          z-index: 99;
        }

        ${mediaMax.xs} {
          &.many {
            ${Btn} {
              padding: 2rem 2.4rem;
            }
          }
        }
      }
    }

    ${LeftWrapper} {
      width: 50%;

      ${mediaMax.xs} {
        width: 90%;
        height: auto;
        align-self: flex-start;
        padding-left: ${p => p.theme.paddingX.m};
      }
    }

    ${RightWrapper} {
      width: 15%;
      align-self: stretch;
      height: auto;

      ${mediaMax.xs} {
        width: 60%;
        align-self: end;
      }

      ${Image} {
        position: absolute;
        top: 12.5rem;
        right: 0;
        width: 100%;
        transform: translateY(-50%);

        ${mediaMax.xs} {
          right: ${p => p.theme.paddingX.m};
          position: relative;
          top: initial;
          transform: none;
        }
      }
    }
  }

  &.small {
    ${LeftWrapper},
    ${CenterWrapper},
    ${RightWrapper} {
      width: calc(100% / 3);
      ${mediaMax.xs} {
        width: 100%;
      }
    }

    ${LeftWrapper} {
      padding-left: 5%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      ${mediaMax.xs} {
        padding-left: ${p => p.theme.paddingX.m};
      }

      ${Image} {
        width: 50%;
      }
    }

    ${CenterWrapper} {
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      ${mediaMax.xs} {
        padding-left: ${p => p.theme.paddingX.m};
      }
    }

    ${RightWrapper} {
      padding-right: 5%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ${mediaMax.xs} {
        padding-right: ${p => p.theme.paddingX.m};
      }

      ${Image} {
        width: 90%;
        position: initial;
        top: auto;
        left: auto;
      }
    }
  }

  &.inverted {
    flex-direction: row-reverse;
    ${mediaMax.xs} {
      flex-direction: column-reverse;
    }

    ${CenterWrapper} {
      padding: 0;
      ${mediaMax.xs} {
        padding-left: ${p => p.theme.paddingX.m};
      }

      ${TextSubtitle} {
        margin-bottom: 1.5rem;
        font-size: 1.1rem;
        font-weight: initial;
      }

      ${TextTitle} {
        font-size: 4.5rem;
        margin-bottom: 2rem;
      }

      ${TextAction} {
        margin-top: 2rem;
      }
    }

    ${RightWrapper} {
      align-self: stretch;
      height: auto;
      ${mediaMax.xs} {
        height: 10rem;
        width: auto;
        justify-content: flex-start;
        padding-left: ${p => p.theme.paddingX.m};

      }

      ${Image} {
        max-height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        right: auto;
        object-fit: cover;
        transform: translateX(-50%);
        ${mediaMax.xs} {
          transform: none;
          position: relative;
          left: auto;
          right: auto;
        }
      }
    }

    ${LeftWrapper}{
      ${mediaMax.xs} {
        height: 30rem;
        width: auto;
        justify-content: flex-end;
        padding-left: ${p => p.theme.paddingX.m};
        ${mediaMax.xs} {
          left: -${p => p.theme.paddingX.m};
        }
        ${Image} {
          width: 90%;
        }
      }
    }
  }
`
